import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/Layout/layout"
// import Image from "../components/image"
import SEO from "../../components/SEO/seo"
import HeroInner from "../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../components/ArticleFullWidth/article-full-width"
import Banner from "../../components/Banner/banner"
import UnorderedList from "../../components/UnorderedList/unordered-list"

// import css from "./about-us.module.scss"

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <HeroInner
      title="About Us"
      imageAlt="About Us"
      imagePath="alfons-morales-YLSwjSy7stw-unsplash.jpg"
    />
    <ArticleFullWidth
      title="Our Company"
      descriptions={[
        `Blend-Divine Private Limited is a global B2B platform to serve the needs of retailers worldwide. It’s an accelerator and incubator, focusing on Product and Services related to Wholesale E-Commerce Industry. We cater to the businesses/Non-Profit organization as mentioned below.`,
      ]}
    />
    <div className="container">
      <UnorderedList
        items={[
          `Boutiques and brick-and-mortars`,
          `Entrepreneur`,
          `Partner or reseller websites`,
          `Schools and universities`,
          `Organizations`,
          `Tradeshows and conferences`,
        ]}
      />
    </div>

    <ArticleFullWidth
      descriptions={[
        `The platform was created with the needs of wholesale vendors and buyers in mind. Blend-Divine helps retailers, find and purchase unique products online in the Education, apparel, fashion accessories, health, Office products, arts and crafts categories.`,
        `We simplify and facilitate the wholesale trade on an online platform, making wholesale purchases simple, even international purchases.`,
      ]}
    />
    <Banner
      title="Mission"
      description="Blend-Divine’s mission is to contribute to the development of Businesses &amp; Entrepreneurs through Research based Borderless e-Commerce Products &amp; Services and to generate livelihood opportunities to Millions."
    />

    <ArticleFullWidth
      title="Our Vision"
      descriptions={[
        `The vision of the Blend-Divine is not only to facilitate the global wholesale trade but also to help and assist small to medium size businesses to reach new buyers globally and increase profits by improving their supply chain. Blend-Divine soon will become wholesale suppliers’ main marketing channel. Our marketplace’s vision is also to make it possible for small vendors to enter the retail marketplace without a large initial investment and assist them in their growth through Product Research and Development.`,
      ]}
    />
  </Layout>
)

export default AboutPage
