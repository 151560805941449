import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import css from "./banner.module.scss"

const Banner = ({ title, description, ctaText, ctaLink }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "alfons-morales-YLSwjSy7stw-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={css.banner}>
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      <div className={css.banner__content}>
        <div className={css.banner__container}>
          <h3 className={css.banner__title}>{title}</h3>
          <p className={css.banner__desc}>{description}</p>
          {ctaLink && ctaText && (
            <Link className={css.banner__cta} to={ctaLink}>
              {ctaText}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

Banner.defaultProps = {
  title: "",
}
export default Banner
